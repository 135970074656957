<template>
  <!--begin::Tiles Widget 8-->
  <div class="card card-custom gutter-b d-none d-lg-block d-xs-none" :class="{ 'card-stretch': cardStretch }">
    <!-- card-stretch -->
    <!--begin::Header-->
    <div class="card-header">
      <div class="card-title">
        <div class="card-label">
          <div class="font-weight-bolder">Sua Carteira Snail</div>
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0">
      <OverlayLoader
        :is-submitting="!wallet.address"
        message="Sua Wallet é criada automaticamente após a validação dos seus documentos."
        :timing="true"
      >
        <!--begin::Items-->
        <div class="flex-grow-1 card-spacer">
          <div class="text-center mb-10">
            <qrcode-vue
              class="qrCode"
              :value="wallet.address"
              level="H"
              render-as="svg"
              :foreground="this.class.color"
            />
          </div>
          <hr />
          <!--begin::Item-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center mr-2">
              <div>
                <span class="font-size-h6 text-dark-75 font-weight-bolder">Saldo</span>
              </div>
            </div>
            <div
              :class="'label label-' + this.class.textClass + ' label-inline font-weight-bold py-4 px-3 font-size-base'"
            >
              <span v-if="walletType == 'snail'">
                {{ wallet.balance | currencySnail() }}
              </span>
              <span v-else>
                {{ wallet.balance | currencyReal() }}
              </span>
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center mr-2">
              <div>
                <span class="font-size-h6 text-dark-75 font-weight-bolder">Data criação</span>
              </div>
            </div>
            <div
              :class="'label label-' + this.class.textClass + ' label-inline font-weight-bold py-4 px-3 font-size-base'"
            >
              {{ formatDateGet(wallet.created_at) }}
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center mr-2">
              <div>
                <span class="font-size-h6 text-dark-75 font-weight-bolder">Email</span>
              </div>
            </div>
            <div
              :class="'label label-' + this.class.textClass + ' label-inline font-weight-bold py-4 px-3 font-size-base'"
            >
              {{ currentUser.email }}
            </div>
          </div>
          <!--end::Item-->
        </div>

        <!--end::Items-->
      </OverlayLoader>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Tiles Widget 8-->
</template>

<script>
import { mapGetters } from 'vuex';
import QrcodeVue from 'qrcode.vue';
import OverlayLoader from '@/shared/content/OverlayLoader.vue';

export default {
  name: 'Widget4',
  components: {
    QrcodeVue,
    OverlayLoader
  },
  props: {
    cardStretch: {
      type: Boolean,
      required: false
    },
    walletType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      class: {
        color: null,
        textClass: null
      },
      wallet: {
        balance: 0,
        address: '',
        created_at: '--'
      }
    };
  },
  computed: {
    ...mapGetters(['currentWalletSnail', 'currentWalletReal', 'currentUser'])
  },
  async mounted() {
    if (this.walletType == 'snail') {
      if (this.currentWalletSnail) {
        this.wallet = await this.currentWalletSnail;
        this.class = {
          color: '#1775c6',
          textClass: 'primary'
        };
      }
    }

    if (this.walletType == 'real') {
      if (this.currentWalletReal) {
        this.wallet = await this.currentWalletReal;
        this.class = {
          color: '#2e9e01',
          textClass: 'success'
        };
      }
    }
  },
  methods: {
    formatDateGet(value) {
      return this.$moment(new Date(value)).format('MM/DD/YYYY');
    }
  }
};
</script>
